import React from 'react';
import MainImage from "./MainImage";

const BlogPostsList = ({ posts }) => {
  return posts.map((blog) => (
    <div className="row" key={blog.id}>
      <div className="col-xs-18 col-md-offset-1 col-sm-6 col-md-6">
        {blog.mainImage && (
          <a href={`/blog/${blog.slug.current}`}>
            <MainImage
              data={blog.mainImage}
              width={500}
              alt={`Featured Image for ${blog.title}`}
            />
          </a>
        )}
      </div>
      <div className="col-xs-14 col-sm-12 col-md-10">
        <h2>
          <a href={`/blog/${blog.slug.current}`}>{blog.title}</a>
        </h2>
        <p>{blog.excerpt}</p>
      </div>
    </div>
  ))
}

export default BlogPostsList;
